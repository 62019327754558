import Layout from "components/Layout";
import Home from "views/pages/home";
// import Seedround from "views/pages/buySft/seedround";
// import Private from "views/pages/buySft/private";
// import Farms from "views/pages/farm";
// import NFTGames from "views/pages/games";
// import Home from "views/pages/home";
import Info from "views/pages/info";
// import Pools from "views/pages/pools";
import Staking from "views/pages/staking";
// import Stakev2 from "views/pages/stakev2";
// import Promotion from "views/pages/promotion";

export const router = [
  
  // {
  //   title: "Promotion",
  //   path: "/safety/promotion",
  //   component: Promotion,
  //   layout: Layout,
  //   exact: true,
  //   icon: "/assets/images/622-6229100_stack-of-coins-icon-clipart-computer-icons-coin.svg",
  // },
  // {
  //   title: "Buy SFT",
  //   path: "/safety",
  //   component: null,
  //   layout: Layout,
  //   exact: true,
  //   icon: "/assets/images/svg-export/icon-wallet.svg",
  //   children: [
  //     {
  //       title: "Seedround",
  //       path: "/safety/seedround",
  //       component: Seedround,
  //       layout: Layout,
  //       exact: false,
  //       symbol: "Seedround",
  //     },
  //     {
  //       title: "Private",
  //       path: "/safety/private",
  //       component: Private,
  //       layout: Layout,
  //       exact: false,
  //       symbol: "privateSale",
  //     },
  //   ],
  // },
  {
    title: "NetWork",
    path: "/app/info",
    component: Info,
    layout: Layout,
    exact: true,
    icon: "/assets/images/information.svg",
  },
  {
    title: "Staking",
    path: "/app/home",
    component: Staking,
    layout: Layout,
    exact: true,
    icon: "/assets/images/622-6229100_stack-of-coins-icon-clipart-computer-icons-coin.svg",
  },
  // {
  //   title: "Swap",
  //   path: "/btcf/trade",
  //   component: Home,
  //   layout: Layout,
  //   exact: true,
  //   icon: "/assets/images/trade.png",
  // },
  // {
  //   title: "Staking",
  //   path: "/safety/staking",
  //   component: Staking,
  //   layout: Layout,
  //   exact: true,
  //   icon: "/assets/images/622-6229100_stack-of-coins-icon-clipart-computer-icons-coin.svg",
  //   children: [
  //     {
  //       title: "V1 ( Old )",
  //       path: "/safety/staking",
  //       component: Staking,
  //       layout: Layout,
  //       exact: false,
  //       symbol: "v1",
  //     },
  //     {
  //       title: "V2 ( New )",
  //       path: "/safety/staking-v2",
  //       component: Stakev2,
  //       layout: Layout,
  //       exact: false,
  //       symbol: "v2",
  //     }
  //   ]
  // },
  // {
  //   title: "Swap",
  //   path: "https://swap.sftdefi.info/swap",
  //   component: null,
  //   layout: Layout,
  //   exact: true,
  //   icon: "/assets/images/svg-export/icon-swap.svg",
  // },
  // {
  //   title: "Farms",
  //   path: "/safety/farms",
  //   component: Farms,
  //   layout: Layout,
  //   exact: true,
  //   icon: "/assets/images/svg-export/icon-farms.svg",
  // },
  // {
  //   title: "Pools",
  //   path: "/safety/pools",
  //   component: Pools,
  //   layout: Layout,
  //   exact: true,
  //   icon: "/assets/images/svg-export/icon-pools.svg",
  // },
  // {
  //   title: "NFT Game",
  //   path: "/safety/NFT-Games",
  //   component: NFTGames,
  //   layout: Layout,
  //   exact: true,
  //   icon: "/assets/images/svg-export/icon-NFT-games.svg",
  // },

  // {
  //   title: "Docs",
  //   path: "https://docs.sftdefi.info/",
  //   component: null,
  //   layout: Layout,
  //   exact: true,
  //   icon: "/assets/images/svg-export/icon-docs.svg",
  // },
];
